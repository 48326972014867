var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { attrs: { name: "formClient" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title:
                  "" +
                  _vm.$t("Ficha Cadastral") +
                  (_vm.person.id ? " #" + _vm.person.id : ""),
              },
            },
            [
              _c("customer-form", {
                ref: "clientForm",
                attrs: {
                  person: _vm.person,
                  "is-read-only": _vm.isReadOnly,
                  "is-edit": _vm.isEdit,
                },
                on: {
                  "clean-person-data": _vm.cleanPersonTypeData,
                  "show-price-table": _vm.onShowPriceTableSidebar,
                },
              }),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title: "Características",
              },
            },
            [
              _c(
                "b-tabs",
                { attrs: { fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("Endereços") } },
                    [
                      _c("e-address-tab", {
                        attrs: {
                          "is-read-only": _vm.isReadOnly,
                          person: "Customer",
                          addresses: _vm.getAddresses,
                        },
                        on: {
                          "add-address": function (formData) {
                            return _vm.addItem("addresses", formData)
                          },
                          "update-address": function (formData) {
                            return _vm.udpateItem("addresses", formData)
                          },
                          "remove-address": function (id) {
                            return _vm.removeItem("addresses", id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("Contatos") } },
                    [
                      _c("e-contact-tab", {
                        attrs: {
                          "is-read-only": _vm.isReadOnly,
                          contacts: _vm.getContacts,
                          person: "Customer",
                        },
                        on: {
                          "add-contact": function (formData) {
                            return _vm.addItem("contacts", formData)
                          },
                          "update-contact": function (formData) {
                            return _vm.udpateItem("contacts", formData)
                          },
                          "remove-contact": function (id) {
                            return _vm.removeItem("contacts", id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("Histórico") } },
                    [
                      _c("history-tab", {
                        attrs: { "customer-id": _vm.person.id },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("price-table-sidebar", {
        ref: "pricetable_sidebar",
        on: {
          save: function () {
            return _vm.fetchPriceTables()
          },
        },
      }),
      _c("e-crud-actions", {
        attrs: { "show-save": !_vm.isReadOnly, busy: _vm.loading },
        on: { save: _vm.saveClient, cancel: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }